<template>
  <div class="table-reglement-fac-dialog">
    <v-data-table
      :headers="headers"
      :items="table2"
      class="table-facture"
      hide-default-footer
      hide-action
      disable-sort
      no-data-text="Il n'y a aucun enregistrement à afficher"
    >
      <template v-slot:[`item.facture`]="{}">
        <td class="custom-cell">
          {{ table1.num }}
        </td>
      </template>
      <template v-slot:[`item.avoir`]="{ item }">
        <td class="custom-cell">
          {{ item.num }}
        </td>
      </template>
      <template v-slot:[`item.ht`]="{ item }">
        <td class="custom-cell">{{ item.total_ht }} €</td>
      </template>
      <template v-slot:[`item.vide`]="{ item }">
        <td class="custom-cell flex-div">
          <div class="block-icon-table-facture mr-2 ml-1">
            <font-awesome-icon
              v-if="visualisation"
              icon="eye"
              title="Visualisation Facture"
              @click.prevent.stop="handleOpenAvoirVusialisationModal(item)"
            />
          </div>
          <div class="block-icon-table-facture mr-2">
            <font-awesome-icon
              v-if="checkPermission('GAPTHTPFAP')"
              title="Télecharger Facture"
              icon="arrow-alt-circle-down"
              @click.prevent.stop="clickButton(item.id)"
            />
          </div>
        </td>
      </template>
    </v-data-table>
    <!--VISUALISATION FACTURE-->
    <v-dialog
      v-model="vusialsationAvoir"
      persistent
      max-width="900px"
      hide-overlay
      scrollable
      content-class="custom-vuetify-dialog-show"
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Visualisation Facture</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="ModalShowFacture"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr"></v-divider>
        <v-card-text class="body-card">
          <div
            id="hmt_template_block_id"
            v-if="templateAvoir"
            v-html="templateAvoir"
          ></div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            outlined
            @click="ModalShowFacture"
            title="Fermer"
            color="#704ad1"
          >
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    table1: { required: true },
    table2: { required: true },
    visualisation: { required: true },
    telecharger: { required: true }
  },
  components: {},
  data() {
    return {
      confirmValidFcature: false,
      templateAvoir: null,
      headers: [
        {
          text: 'Facture',
          sortable: false,
          value: 'facture'
        },
        { text: 'Avoir', value: 'avoir', sortable: false },
        { text: 'HT', value: 'ht', sortable: false },
        { text: '', value: 'vide', sortable: false, width: '70px !important' }
      ],
      vusialsationAvoir: false
    };
  },
  computed: {
    ...mapGetters(['checkPermission']),
    ComputedFilter() {
      return {
        filterOrganisme: this.filterOrganisme,
        filterFiliale: this.filterFiliale,
        filterLot: this.filterLot,
        num: this.searchValue,
        date_debut: this.date_debut,
        date_fin: this.date_fin
      };
    }
  },
  mounted() {},
  methods: {
    ...mapActions(['validationFactureObligee', 'downloadFactureObligeeItem']),
    ModalShowFacture() {
      this.vusialsationAvoir = false;
    },
    hideModal() {
      this.confirmValidFcature = false;
    },
    async validationGlobalFactureObligee() {
      this.error = null;
      if (this.type.length == 0) {
        this.error = 'Il faut sélectionner au moins un type';
      } else {
        this.confirmValidFcature = false;
        const response = await this.validationFactureObligee({
          lot: this.filterLot,
          organisme: this.filterOrganisme,
          date_debut: this.date_debut,
          date_fin: this.date_fin,
          filiale: this.filterFiliale,
          dataCheck: this.computedgetLotsToSend,
          type: this.type
        });
        if (response) {
          this.$swal
            .fire({
              icon: 'info',
              confirmButtonText: 'Ok',
              html:
                '<h5>Nombre des factures générées ' +
                this.getResponsevalidationGlobal.success +
                '</h2><br>' +
                '<h5>Nombre des factures déjà existantes ' +
                this.getResponsevalidationGlobal.exist +
                '</h5><br>' +
                '<h5>Nombre des factures erronées ' +
                this.getResponsevalidationGlobal.error +
                '</h5>'
            })
            .then(result => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.handleFilter();
              }
            });
        }
      }
    },
    async handleOpenAvoirVusialisationModal(item) {
      this.vusialsationAvoir = true;
      this.templateAvoir = item.body;
    },
    clickButton(id) {
      this.downloadFactureObligeeItem({
        id: id
      });
    }
  }
};
</script>
